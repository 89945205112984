<template>
  <v-row>
    <v-col cols="12" class="text-center pb-0 px-14">
      <ChartDonutFLRatio :chart-data="chartData" />
    </v-col>

    <v-col cols="12" class="pt-0">
      <v-row
        align="center"
        justify="center"
        class="font-weight-bold main--text py-4 mt-2"
      >
        <v-col cols="auto">
          <div class="text-heading-4">
            {{ $t("fl_ratio") }}
          </div>
        </v-col>

        <v-col cols="auto">
          <div class="text-fl-ratio-percentage">
            <number-display
              :from="0"
              :to="flRatio"
              :duration="1"
              :format="formatToPercent"
              easing="Power1.easeOut"
            />
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-row align="center" justify="center">
        <v-col cols="auto" class="px-1">
          <div class="chart-legend-box food-costs"></div>
        </v-col>
        <v-col cols="auto" class="pl-1">
          <span
            class="font-weight-medium main--text lighten-2 text-heading-3"
            style="line-height: 15px"
          >
            {{ $t("fl_ratio_food") }}
          </span>
        </v-col>

        <v-col cols="auto" class="px-1">
          <div class="chart-legend-box labor-costs"></div>
        </v-col>
        <v-col cols="auto" class="pl-1">
          <span
            class="font-weight-medium main--text lighten-2 text-heading-3"
            style="line-height: 15px"
          >
            {{ $t("fl_ratio_labor") }}
          </span>
        </v-col>

        <v-col cols="auto" class="px-1">
          <div class="chart-legend-box other-costs"></div>
        </v-col>
        <v-col cols="auto" class="px-1">
          <span
            class="font-weight-medium main--text lighten-2 text-heading-3"
            style="line-height: 15px"
          >
            {{ $t("fl_ratio_other") }}
          </span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ChartDonutFLRatio from "@/components/charts/ChartDonutFLRatio";
import NumberDisplay from "@/components/ui/Number";
export default {
  name: "FLRatioCumulativeChart",
  components: { NumberDisplay, ChartDonutFLRatio },
  props: {
    chartData: {
      type: Object,
      default: () => {},
      required: true
    },
    flRatio: {
      type: Number,
      default: 0.0,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    formatToPercent(value) {
      if (value === 100) {
        return `${value}<span class="decimal-point-label-fl-ratio">.0%</span>`;
      } else {
        let decimal = value.toFixed(1).split(".")[1];
        return `${Math.floor(
          value
        )}<span class="decimal-point-label-fl-ratio">.${decimal}%</span>`;
      }
    }
  }
};
</script>

<style scoped>
.text-fl-ratio-percentage {
  font-size: 38px !important;
}
.chart-legend-box.food-costs {
  background-color: #069821;
}

.chart-legend-box.labor-costs {
  background-color: #f99f1c;
}

.chart-legend-box.other-costs {
  background-color: #c8c7b6;
}
::v-deep .decimal-point-label-fl-ratio {
  font-size: 25px;
}
</style>
