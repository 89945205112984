<script>
import { Doughnut, mixins } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";
const { reactiveProp } = mixins;

export default {
  name: "ChartDonutFLRatio",
  extends: Doughnut,
  mixins: [reactiveProp],
  data() {
    return {
      chartOptions: {
        responsive: true,
        devicePixelRatio: 1,
        maintainAspectRatio: true,
        showTooltips: false,
        tooltips: {
          enabled: false
        },
        legend: {
          display: false,
          position: "bottom",
          labels: {
            fontSize: 14,
            fontFamily: "Roboto"
          }
        },
        plugins: {
          datalabels: {
            color: "black",
            font: {
              size: 14,
              weight: "700",
              family: "Roboto"
            },
            formatter: function(value) {
              return `${value}%`;
            }
          },
          customAnnotationLabel: false,
          deferred: {
            xOffset: 150,
            yOffset: "100%",
            delay: 500
          }
        }
      }
    };
  },
  mounted() {
    this.addPlugin(ChartDataLabels);
    this.renderChart(this.chartData, this.chartOptions);
  }
};
</script>

<style scoped></style>
